<template>
    <div class="sticky top-0 inset-x-0 z-20 bg-white border-y px-4 sm:px-6 md:px-8 lg:hidden dark:bg-dark dark:border-gray-700">
        <div class="flex items-center py-4">
            <button type="button" class=" hover:text-gray-600 flex justify-start w-full" data-hs-overlay="#application-sidebar" aria-controls="application-sidebar" aria-label="Toggle navigation">
                <Icon class="i-heroicons-bars-3 block h-6 w-6" title="Toggle Navigation" />
            </button>
            <div class="flex justify-center text-center px-6 items-center">
                <a href="/">
                    <span class="rounded-full text-primary text-2xl font-title font-bold uppercase dark:text-white whitespace-nowrap">{{ appConfig.name }}</span>
                </a>
            </div>
            <button type="button" class="flex justify-end  w-full hover:text-gray-600" data-hs-overlay="#user-sidebar" aria-controls="user-sidebar" aria-label="Toggle navigation">
                <Icon class="i-heroicons-user-circle block h-6 w-6" title="Toggle Profile" />
            </button>
        </div>
    </div>

    <div id="application-sidebar" class="hs-overlay hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform hidden fixed top-0 left-0 bottom-0 z-[60] w-64 bg-white border-r border-gray-200 pt-7 pb-10 overflow-y-auto scrollbar-y lg:flex lg:flex-col lg:flex-grow lg:translate-x-0 lg:right-auto lg:bottom-0 dark:scrollbar-y dark:bg-dark dark:border-gray-700">
        <div class="hidden lg:block text-center px-6 pb-6 items-center">
            <a href="/">
                <span class="rounded-full text-primary text-2xl font-title font-bold uppercase dark:text-white">{{ appConfig.name }}</span>
            </a>
        </div>

        <nav class="hs-accordion-group px-6 w-full flex flex-col flex-wrap flex-grow" data-hs-accordion-always-open>
            <ul class="flex flex-col flex-1 gap-7">
                <li>
                    <ul class="space-y-1.5 -m-2">
                        <li v-for="item in navigation" :key="item.name">
                            <NuxtLink :to="item.href" class="js-menu-link flex items-center gap-x-3 rounded-md hover:bg-light-secondary p-2 text-base font-semibold leading-6 dark:hover:bg-dark-secondary" activeClass="bg-light-secondary dark:bg-dark-secondary" ariaCurrentValue="page" >
                                <Icon :class="item.icon" class="h-6 w-6" />
                                {{ item.name }}
                            </NuxtLink>
                        </li>
                    </ul>
                </li>
                <li class="border-t border-gray-300 pt-4">
                    <div class="text-lg leading-6 mb-2">Admin</div>
                    <ul class="space-y-1.5 -m-2">
                        <li v-for="item in adminNavigation" :key="item.name">
                            <NuxtLink :to="item.href" class="js-menu-link flex items-center gap-x-3 rounded-md hover:bg-light-secondary p-2 text-base font-semibold leading-6 dark:hover:bg-dark-secondary" activeClass="bg-light-secondary dark:bg-dark-secondary" ariaCurrentValue="page" >
                                <Icon :class="item.icon" class="h-6 w-6" />
                                {{ item.name }}
                            </NuxtLink>
                        </li>
                    </ul>
                </li>
                <li class="hidden lg:block mt-auto">
                    <ul class="space-y-1.5">
                        <li v-for="item in userNavigation" :key="item.name">
                            <NuxtLink :to="item.href" class="js-menu-link flex items-center gap-x-3 rounded-md hover:bg-light-secondary p-2 text-base font-semibold leading-6 dark:hover:bg-dark-secondary" activeClass="bg-light-secondary dark:bg-dark-secondary" ariaCurrentValue="page" >
                                <Icon :class="item.icon" class="h-6 w-6" />
                                {{ item.name }}
                            </NuxtLink>
                        </li>
                        <li>
                            <a @click=signOut() class="flex items-center gap-x-3 rounded-md hover:bg-light-secondary p-2 text-base font-semibold leading-6 dark:hover:bg-dark-secondary cursor-pointer">
                                <Icon class="i-heroicons-arrow-left-on-rectangle h-6 w-6" />
                                Log Out
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>

    <div id="user-sidebar" class="hs-overlay hs-overlay-open:translate-x-0 translate-x-full transition-all duration-300 transform hidden fixed top-0 right-0 bottom-0 z-[60] w-64 bg-white border-r border-gray-200 pt-7 pb-10 overflow-y-auto scrollbar-y dark:scrollbar-y dark:bg-dark dark:border-gray-700">
        <nav class="hs-accordion-group p-6 w-full flex flex-col flex-wrap flex-grow" data-hs-accordion-always-open>
            <ul class="flex flex-col flex-1 gap-7">
                <li class="lg:hidden mt-auto">
                    <ul class="space-y-1.5">
                        <li v-for="item in userNavigation" :key="item.name">
                            <NuxtLink :to="item.href" class="js-menu-link flex items-center gap-x-3 rounded-md hover:bg-light-secondary p-2 text-base font-semibold leading-6" activeClass="bg-light-secondary dark:bg-dark-secondary" ariaCurrentValue="page" >
                                <Icon :class="item.icon" class="h-6 w-6" />
                                {{ item.name }}
                            </NuxtLink>
                        </li>
                        <li>
                            <a @click=signOut() class="flex items-center gap-x-3 rounded-md p-2 text-base font-semibold leading-6 cursor-pointer">
                                <Icon class="i-heroicons-arrow-left-on-rectangle h-6 w-6" />
                                Log Out
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script setup lang="ts">
onMounted(() => {
    Array.from(document.getElementsByClassName('js-menu-link')).forEach(function(element) {
      element.addEventListener('click', event => HSOverlay.close(event.target.closest('.hs-overlay')))
    })
})  

const { signOut } = useAuth()
const appConfig = useAppConfig()

const navigation = [
    { name: 'Dashboard', href: '/admin/dashboard', icon: 'i-heroicons-home', },
]
const adminNavigation = [
    { name: 'Questionnaires', href: '/admin/questionnaire/edit', icon: 'i-heroicons-document-text' },
    { name: 'Review', href: '/admin/review', icon: 'i-heroicons-pencil-square' },
    { name: 'Badges', href: '/admin/badge', icon: 'i-heroicons-trophy' },
    { name: 'Accounts', href: '/admin/accounts', icon: 'i-heroicons-users' },
]
const userNavigation = [
    { name: 'Your Profile', href: '/admin/profile', icon: 'i-heroicons-user' },
    { name: 'Settings', href: '/admin/settings', icon: 'i-heroicons-cog-6-tooth' },
]
</script>