<template>
    <div class="bg-admin-bg bg-no-repeat bg-bottom lg:bg-right-bottom min-h-full pb-10 lg:py-10 bg-light-secondary dark:bg-dark-secondary dark:text-white">
        <AdminNavBar/>

        <main class="w-full bg-light-secondary px-4 pt-10 lg:py-0 sm:px-6 md:px-8 lg:pl-72 overflow-x-hidden overflow-y-auto dark:text-white dark:bg-dark-secondary">
            <div v-if="loading" class="min-h-[15rem] flex flex-col bg-white border shadow-sm dark:bg-dark dark:border-gray-700 dark:shadow-gray-700/[.7]">
                <AdminLoader />
            </div>
            <div id="page-container" :class="loading === true ? 'hidden' : ''">
                <slot />
            </div>
        </main>
        
        <DarkSwitch />
    </div>
</template>

<script setup lang="ts">
useHead({
    title: 'Impact Up',
    htmlAttrs: {class: 'admin'},
    meta: [{
        name: 'description',
        content: 'My amazing site.'
    }],
})

const nuxtApp = useNuxtApp()
const loading = ref(false)
nuxtApp.hook("page:start", () => {
    loading.value = true
})
nuxtApp.hook("page:finish", () => {
    loading.value = false
})
</script>